<template>
  <div class="ratio" :class="`ratio-${aspectRatio}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'HMFixedAspectRatioContainer',
  props: {
    aspectRatio: {
      type: String,
      default: '16x9',
    },
  },
};
</script>

<style lang="scss" scoped>
.ratio > * {
    height: 100%;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%
}
.embed-responsive-item > * {
  width: 100%;
  height: 100%;
}
.embed-responsive-item > img {
  object-fit: cover;
}
</style>

<style lang="scss">
// .hm-fixed-aspect-ratio-container > * {
//     height: 100%;
//     left: 0;
//     position: absolute !important;
//     top: 0;
//     width: 100%
// }
</style>
